<template>
    <div v-myTitle :data-title="info.name +'·'+className+'-'+'服务中心'+'-'+'企胖胖'">
        <div class="content">
            <div class="head">
                <div class="nav">
                    <div @click="goBack">
                        <i style="font-size: 20px; padding-top: 2px" class="el-icon-arrow-left"></i>
                        返回
                    </div>
                </div>
            </div>
            <div class="demand-info">
                <div class="title">{{ className }} | {{ info.name }}</div>
                <div class="demand-icon">
                    <div>
                        <img style="width: 19px; height: 24px" src="../../assets/images/serve/dw.png" alt="" />
                        {{ mapAll[info.sendProvince] }}{{ mapAll[info.sendCity] }}
                    </div>
                    <div>
                        <img style="width: 23px; height: 21px" src="../../assets/images/serve/hy.png" alt="" />
                        {{ industryList[info.sendIndustry] }}
                    </div>
                </div>
                <div class="demand-box">
                    <div class="budget">
                        <div>
                            预算<span class="price">￥{{ info.sendMoneyBudget }}</span>
                        </div>
                    </div>
                    <div class="demand-logo">
                        <img :src="$util.host + info.sendUserHeadPic" alt="" />
                        {{ info.sendUserName }}
                    </div>
                    <div class="demand-centent">
                        需求描述
                        <div>{{ info.sendMsgBase }}</div>
                    </div>
                </div>
                <div class="require">
                    预付意向金 <span class="price">￥{{ info.sendMoneyIntention }}</span>
                </div>
                <div class="require">
                    其他要求 <span>{{ info.sendMsgOther }}</span>
                </div>
                <!-- <div class="number">
          数量<el-input-number
            style="margin: 0 10px"
            v-model="num"
            @change="handleChange"
            :min="1"
            :max="10"
            label="描述文字"
          ></el-input-number
          >(接单人数{{ 10 - info.bidSurplusNum }}/10)
        </div> -->
                <div class="foot-btn">
                    <el-button class="orders" :disabled="info.errorState != 0 ? true : false" @click="orders">接单</el-button>
                </div>
            </div>
            <div class="interested">
                <div class="title">可能感兴趣</div>
                <div class="litle-need">
                    <div class="litle-cell" v-for="item in needList" :key="item.id" @click="goDemand(item.id)">
                        <div class="litle-title">{{ item.name }}</div>
                        <div class="tag-box">
                            <div class="tag" v-text="item.sendUserType == 1 ? '个人用户' : '企业用户'"></div>
                            <div class="tag">{{ industryList[item.sendIndustry] }}</div>
                            <div class="tag">
                                {{ mapAll[item.sendProvince] }}{{ mapAll[item.sendCity] }}
                            </div>
                        </div>
                        <div class="litle-money">预算 ￥ {{ item.sendMoneyBudget }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="dialogVisible">
            <Props :dialogVisible="dialogVisible" :infoData="info" @close="close" />
        </div>
        <Complete :show="show" @close="close" />
        <Footer />
    </div>
</template>

<script>
import Footer from "../../components/footer.vue";
import Props from "../../components/serve/props.vue";
import Complete from "../../components/complete.vue";

export default {
    components: { Footer, Props, Complete },
    data() {
        return {
            classList: "",
            className: "",
            num: 1,
            dialogVisible: false, //弹出层是否显示
            id: this.$route.query.id, //需求的ID
            info: {}, //需求详情
            needList: [], //所有需求列表
            show: false,
            mapAll: {}, //所有地址的数据
            industryList: {
                A: "农、林、牧、渔业",
                B: "采矿业,",
                C: "制造业",
                D: "电力、热力、燃气及水生产和供应业",
                E: "建筑业",
                F: "批发和零售业",
                G: "交通运输、仓储和邮政业",
                H: "住宿和餐饮业",
                I: "信息传输、软件和信息技术服务业",
                J: "金融业",
                K: "房地产业",
                L: "租赁和商务服务业",
                M: "科学研究和技术服务业",
                N: "水利、环境和公共设施管理业",
                O: "居民服务、修理和其他服务业",
                P: "教育",
                Q: "卫生和社会工作",
                R: "文化、体育和娱乐业",
                S: "公共管理、社会保障和社会组织",
                T: "国际组织",
            }, //行业
        };
    },
    mounted() {
        this.allData();
        this.getLike();
        this.getAllMap();
        document.getElementById("app").scrollIntoView()
    },
    created() { },
    methods: {
        goDemand(id) {
            // this.$router.push({ path: '/demand', query: { id } })
            this.id = id;
            this.allData();
            this.getLike();
            this.getAllMap();
            document.getElementById("app").scrollIntoView()
        },
        getClass() {
            this.$util.post("/shop/allClassify").then((res) => {
                this.classList = res.data;
                for (var i in this.classList) {
                    if (this.info.classifyId == this.classList[i].id) {
                        this.className = this.classList[i].name;
                    }
                }
            });
        },
        // 获取当前需求的详情
        allData() {
            this.$util
                .post("/demand/det", {
                    orderId: this.id,
                })
                .then((res) => {
                    console.log(res);
                    res.data.state = 0;
                    this.info = res.data;
                    console.log(this.info);
                    this.getClass();
                });
        },
        // 获取所有的地址
        getAllMap() {
            this.$util.post("/address/allMap").then((res) => {
                this.mapAll = res.data.all;
            });
        },
        // 获取所有的需求列表
        getLike() {
            this.$util
                .post("/demand/index", {
                    classifyId: "",
                })
                .then((res) => {
                    this.needList = res.data.defList.data;
                });
        },

        goBack() {
            this.$router.go(-1);
        },
        handleChange(value) {
            console.log(value);
        },
        // 关闭弹框是触发
        close() {
            this.dialogVisible = false;
            this.show = false;
        },
        goUser() {
            this.$router.push({ path: "/user", query: { uid: this.info.sendUid } });
        },
        // 接单
        orders() {
            this.info.orderId = this.info.id
            if (sessionStorage.getItem("token") && sessionStorage.getItem("payPsd") == "true") {
                this.dialogVisible = true;
            } else if (!sessionStorage.getItem("token")) {
                this.$message.error("请先进行登录");
            } else if (sessionStorage.getItem("payPsd") == "false") {
                this.show = true;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.head {
    padding: 12px 0;
    background: #515151;
    margin-bottom: 30px;
    .nav {
        width: 1200px;
        display: flex;
        margin: 0 auto;
        color: #fff;
        div {
            display: flex;
            cursor: pointer;
            align-items: center;
            padding-right: 53px;
            font-size: 14px;
        }
        .bor {
            border-right: 1px solid #707070;
            margin-right: 50px;
        }
    }
}
.content {
    background: #f6f6f6;
    padding-bottom: 200px;
}
.go-back {
    width: 1200px;
    margin: 0 auto 30px;
    font-size: 16px;
    color: #000;
    padding: 15px 0 0;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.demand-info {
    width: 1140px;
    padding: 30px;
    margin: 0 auto;
    background: linear-gradient(#fad3d6, #fefafb);
    .title {
        font-size: 24px;
        color: #222;
        span {
            color: #666666;
        }
    }
    .demand-icon {
        display: flex;
        align-items: center;
        margin: 20px 0 30px;
        font-size: 24px;
        color: #666;
        div {
            display: flex;
            align-items: center;
            margin-right: 60px;
            img {
                margin-right: 10px;
            }
        }
    }
    .demand-box {
        background: #fff;
        padding: 30px;
        .budget {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 30px;
            border-bottom: 1px solid #f1f1f1;
            font-size: 16px;
            div {
                display: flex;
                align-items: center;
            }
            span {
                font-size: 24px;
                color: #222;
                margin-left: 10px;
            }
            .price {
                color: #e40012;
            }
        }
        .demand-logo {
            display: flex;
            align-items: center;
            margin: 30px 0 20px;
            font-size: 24px;
            cursor: pointer;
            color: #222222;
            img {
                width: 67px;
                height: 67px;
                margin-right: 10px;
            }
        }
        .demand-centent {
            display: flex;
            font-size: 16px;
            color: #222;
            div {
                flex: 1;
                color: #999999;
                font-size: 14px;
                margin-left: 10px;
            }
        }
    }
}
.require {
    font-size: 14px;
    color: #222;
    margin: 20px 0;
    // span {
    //     color: #999999;
    //     margin-left: 10px;
    // }
	span {
	    font-size: 24px;
	    color: #222;
	    margin-left: 10px;
	}
	.price {
	    color: #e40012;
	}
	
}
.number {
    display: flex;
    align-items: center;
    color: #222;
    font-size: 14px;
}
.foot-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 90px;
}
.orders {
    // margin-top: 90px;
    width: 247px;
    height: 50px;
    background: #e40012;
    color: #fff;
    font-size: 18px;
    border-radius: 4px;
    // margin: 90px 0 0;
}
.interested {
    width: 1160px;
    background: #fff;
    margin: 30px auto;
    padding: 30px 20px;
    .title {
        margin: 0 0 20px;
        font-size: 24px;
        color: #222;
        padding-bottom: 11px;
        border-bottom: 1px solid #f1f1f1;
    }
    .litle-need {
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        .litle-cell:hover {
            border-color: #e40012;
            background: linear-gradient(#fefafa, #fef2f2);
        }
        .litle-cell {
            cursor: pointer;
            margin-right: 3px;
            background: #fffafa;
            width: 245px;
            padding: 30px 20px 30px;

            margin-bottom: 20px;
            border: 1px solid #fde1e2;
            .litle-title {
                font-size: 24px;
                color: #222;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .tag-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                // flex-wrap: wrap;
                margin: 20px 0 24px;
                .tag {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding: 4px 8px;
                    max-width: 60px;
                    color: #222;
                    font-size: 14px;
                    background: #e3e3e3;
                    margin-right: 10px;
                    border-radius: 4px;
                }
            }
            .litle-money {
                font-size: 18px;
                color: #e40012;
            }
        }
    }
}
// 弹出框

.now {
    font-size: 18px;
    color: #999999;
    margin-bottom: 30px;
}
/deep/.el-step__title.is-wait {
    // background: #e40012;
    color: #666;
}
/deep/.el-step__title.is-process {
    color: #e40012;
}
/deep/.el-step__icon.is-text {
    color: #666666;
    width: 36px;
    height: 36px;
}
/deep/.el-step__head.is-process {
    border-color: #e40012;
}
/deep/.el-step__title.is-finish {
    color: #e40012;
}
/deep/.el-step__head.is-finish {
    border-color: #e40012;
}
.look {
    display: flex;
    align-items: center;
    color: #222;
    font-size: 16px;
    margin: 40px 0;
    div {
        color: #999999;
        margin-right: 20px;
    }
}
.blue {
    color: #2ea7e0;
    font-size: 12px;
    // margin-top: 10px ;
    margin-bottom: 30px;
}
.checked {
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666666;
    span {
        color: #2ea7e0;
    }
}
.state {
    padding: 20px 0 10px;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    font-size: 22px;
    color: #333333;
    span {
        color: #e40012;
    }
    img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }
}
// /deep/.el-input__inner {
//     width: 490px;
// }
.code {
    height: 48px;
    // border: 1px solid #dcdfe6;
    display: flex;
    align-items: center;
    // width: 500px;
    input {
        height: 100%;
        // border: none;
        outline: none;
        padding: 0 15px;
        border-radius: 4px;
        font-size: 16px;
        border: 1px solid #eeeeee;
    }
    button {
        background-color: transparent;
        border: none;
        color: #e40012;
        font-weight: bold;
        font-size: 14px;
    }
}
input::-webkit-input-placeholder {
    color: #c0c4cc;
}
input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #c0c4cc;
}
input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #c0c4cc;
}
input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #c0c4cc;
}
.verification {
    display: flex;
    margin-top: 10px;
    align-items: center;
    .text {
        font-size: 14px;
        color: #666666;
        cursor: pointer;
        margin-left: 10px;
        span {
            color: #e40012;
        }
    }
}
.privacy {
    font-size: 16px;
    color: #00a71c;
    margin: 30px 0 40px;
}
.hint {
    font-size: 16px;
    color: #999999;
    margin: 30px 0;
}
.ysmoney {
    padding: 15px 60px;
    border: 1px solid #eeeeee;
    color: #e40012;
    font-size: 16px;
    // text-align: center;
    text-align: center;
    display: inline-block;
    border-radius: 4px;
}
.payment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    padding: 15px 30px;
    border: 1px solid #eeeeee;
    margin-bottom: 20px;
    color: #999;
}
.raido {
    display: flex;
    align-items: center;
}
.raido-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-left: 10px;
    align-items: center;
    color: #222;
    div {
        display: flex;
        align-items: center;
    }
    img {
        margin-right: 10px;
        // margin-top: ;
    }
    span {
        color: #999999;
        font-size: 14px;
        margin-left: 10px;
    }
}
.deposit {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #222;
    span {
        color: #e40012;
    }
    .price {
        padding: 15px 48px;
        border: 1px solid #eeeeee;
        margin-right: 20px;
        text-align: center;
        div {
            color: #999999;
            font-size: 14px;
            margin-top: 10px;
        }
    }
}
.paypass {
    display: flex;
    align-items: center;
    input {
        width: 64px;
        height: 64px;
        border: 1px solid #d1d1d1;
        outline: none;
        text-align: center;
        line-height: 64px;
        font-size: 60px;
        margin-right: 20px;
    }
}
::-ms-clear {
    display: none;
}

::-ms-reveal {
    display: none;
}

.orderInformation {
    padding: 20px;
    border: 1px solid #eeeeee;
    margin: 30px 0 30px;
    .cell {
        display: flex;
        font-size: 16px;
        color: #999999;
        margin-bottom: 10px;
        div {
            width: 100px;
        }
        span {
            color: #222222;
            margin-left: 65px;
        }
        .blue {
            font-size: 16px;
            color: #2ea7e0;
        }
    }
}
.accept {
    display: flex;
    font-size: 16px;
    color: #999999;
    span {
        margin-top: 15px;
    }
    div {
        flex: 1;
        height: 160px;
        border: 1px solid #eeeeee;
        padding: 15px 10px;
        margin-left: 20px;
    }
}
.accepting {
    padding: 20px;
    border: 1px solid #eeeeee;
    .cell {
        font-size: 16px;
        color: #222222;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        div {
            width: 100px;
            color: #999999;
            margin-right: 50px;
        }
    }
}
.wait {
    font-size: 18px;
    color: #999999;
    text-align: center;
    margin-top: 30px;
}
.wait-btn {
    background: #d1d1d1;
}
.give-up {
    margin-left: 80px;
    font-size: 18px;
    color: #e40012;
}
.no-win {
    text-align: center;
    font-size: 18px;
    color: #999999;
    margin-top: 150px;
    margin-bottom: 70px;
    span {
        color: #e40012;
    }
    img {
        width: 56px;
        height: 56px;
        display: block;
        margin: 0 auto 20px;
    }
}
</style>
